import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Arrow from '../../images/link-arrow.svg'
import { appendUrlPartnerTracking } from '../../util/url'

export function getResourceCardColor(text: string) {
  switch (text) {
    case 'Blog':
      return '#00A3C7'
    case 'Events':
      return '#66C1BF'
    case 'Downloads':
      return '#C70F55'
    case 'White Papers':
      return '#F7A823'
    default:
      return '#00A3C7'
  }
}


interface ResourcesCardInterface {
  post: any
  index: number
}

export const ResourcesItem: React.FC<ResourcesCardInterface> = ({
  post,
  index,
}) => {


  function formatDate(date: string){
      const month = date.substring(5, 7)
      let formatedMonth =''
      switch (month){
        case '01':formatedMonth = 'January'
        break
        case '02':formatedMonth = 'February'
        break
        case '03':formatedMonth = 'March'    
        break
        case '04':formatedMonth = 'April'
        break
        case '05':formatedMonth = 'May'    
        break 
        case '06':formatedMonth = 'June'   
        break    
        case '07':formatedMonth = 'July'    
        break  
        case '08':formatedMonth = 'August'  
        break      
        case '09':formatedMonth = 'September' 
        break      
        case '10':formatedMonth = 'October'   
        break    
        case '11':formatedMonth = 'November'  
        break
        case '12':formatedMonth = 'December'
     break
      }

      const formatedDay = date.substring(8, 10) 
      const formatedYear = date.substring(0, 4) 
      
      const formatedDate =  formatedDay + " " +formatedMonth + ", " + formatedYear 
      return formatedDate
  }
  let featureImage = null;

  if(post.resources.featuredImage){
    featureImage= post.resources.featuredImage.localFile.childImageSharp.fluid
 
  }
  if (index === 0) {
       return (
      <FirstPostContainer>
        {featureImage && <FirstImageContainer><FirstImage fluid={featureImage} /></FirstImageContainer>}
        <TextContainer>
          <FirstPostInfoBar>
            <Pill color={getResourceCardColor(post.resources.category)}>
              {post.resources.category}
            </Pill>
            <Date>{formatDate(post.date)}</Date>
          </FirstPostInfoBar>
          <FirstPostTitle>{post.title}</FirstPostTitle>
          <FirstDescription>{post.resources.description}</FirstDescription>
          <ReadMoreLink to={appendUrlPartnerTracking('/resources/' + post.slug)}>
             <b>Read more </b> <img src={Arrow}/> 
             </ReadMoreLink>
        </TextContainer>
      </FirstPostContainer>
    )
  } else {
    return (
      <PostCard to={appendUrlPartnerTracking('/resources/' + post.slug)}>
        {featureImage &&(

          <SecondaryImage fluid={featureImage} />
        )}
        <TextContainerBackground
          color={getResourceCardColor(post.resources.category)}
        >
          <FirstPostInfoBar>
            <Pill color={getResourceCardColor(post.resources.category)}>
              {post.resources.category}
            </Pill>
            <Date>{formatDate(post.date)}</Date>
          </FirstPostInfoBar>
          <PostTitle>{post.title}</PostTitle>
          <p>{post.resources.description}</p>
        </TextContainerBackground>
      </PostCard>
    )
  }
}

const FirstPostTitle = styled.h3`
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 16px;
  color: #1d1e22;
`
const FirstDescription = styled.p`
  font-weight: 300;
  line-height: 30px;
  padding-bottom: 30px;
  color: #1d1e22;
`

const PostTitle = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 16px;
  color: #1d1e22;
`
const TextContainer = styled.div`
  flex: 1;
 
 width: 50%;
 @media (max-width: 768px ){
width: 100%;
}
`
interface TextContainerBackground {
  color: string
}

const ReadMoreLink = styled(Link)`
display: flex;
flex-direction: row;
height: 20px;

align-items:center;
& img{
height: 15px;
margin-left: 10px;
}
`

const TextContainerBackground = styled.div`
  flex: 1;
  background-color: #fafafa;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  border-top: 3px solid ${(props) => props.color};
  min-height: 360px;
  
  /* max-height: 360px; */
`
const FirstImageContainer = styled.div`
width: 50%;
padding-right: 49px;
display:flex;
align-items: center;
@media (max-width: 768px ){
width: 100%;
padding-right: 0px;
}
`
const FirstImage = styled(Img)`
  width: 100%;

`

const SecondaryImage = styled(Img)`
  //height: 270px;
  width:100%;
 // padding: 40px;
  /* min-width: 420px; */
  
`
const FirstPostContainer = styled.div`
    display: flex;
    border-bottom: 1px solid #dadada;

    flex-direction: row;
    grid-area: 1 / 1 / 2 / 4;
    padding-bottom: 50px;
    margin-bottom: 50px;
    @media (max-width: 1024px) {
      grid-area: 1 / 1 / 2 / 3;
  }
@media (max-width: 768px ){
flex-direction: column;
}
 
`

const FirstPostInfoBar = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`

const PostCard = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

interface PillBackground {
  color: string
}
const Pill = styled.span<PillBackground>`
  background-color: ${(props) => props.color};
  padding: 3px 8px;
  border-radius: 9px;
  color: white;
  font-size: 9px;
  text-transform: uppercase;
  margin-right: 9px;
`
const Date = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: #1d1e22;
`
