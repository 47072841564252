import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const CustomerRightCol: FunctionComponent = ({ children }) => {
  return <Container>{children}</Container>
}

export default CustomerRightCol

const Container = styled.div`
  width: 100%;
  padding-left: 0;
  
  /* p {
    padding-bottom: 55px !important;
  } */
  h2 {
    padding-bottom: 20px !important;
  }
  @media (min-width: 1024px) {
    padding-left: 11%;
  }
`
