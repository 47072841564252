import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import arrhead from '../../images/back-left-arrow.svg'
import { appendUrlPartnerTracking } from '../../util/url'

import IconButton from '@material-ui/core/IconButton'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'

import { Link, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  customHoverFocus: {
    width: 35,
    height: 35,
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#2e2e2e' },
    backgroundColor: 'black',
    color: 'white',
    marginRight: 5,
  },
  icon: {
    width: 20,
    height: 20,
    fill: 'white',
    color: 'white',
  },
}))

const ResourceLeftCol = ({ title }) => {
  const classes = useStyles()
  const [URL, setURL] = useState('')
  useEffect(() => {
    setURL(typeof window !== 'undefined' ? window.location.href : '')
  }, [])
  return (
    <Container>
      <Inner>
        <Title>
          <Course>{title}</Course>
        </Title>
        <BackLink href={appendUrlPartnerTracking('/resources')}>
          <Arrow src={arrhead} alt="Arrow back" />
          <Line />
        </BackLink>
        {/* {description && (
          <Description className="small-p">{description}</Description>
        )} */}

        <Course>SHARE THIS ARTICLE</Course>

        <SocialIcons>
          <StyledIconButton
            aria-label="linkedIn"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${URL}`}
            target="_blank"
            className={classes.customHoverFocus}
          >
            <LinkedInIcon className={classes.icon} />
          </StyledIconButton>

          <StyledIconButton
            aria-label="Twitter"
            href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20great%20article%20from%20Bob's%20Business:%20 ${URL}`}
            target="_blank"
            className={classes.customHoverFocus}
          >
            <TwitterIcon className={classes.icon} />
          </StyledIconButton>

          <StyledIconButton
            aria-label="Facebook"
            // href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
            href={`https://www.facebook.com/sharer/sharer.php?u=${URL}`}
            target="_blank"
            className={classes.customHoverFocus}
          >
            <FacebookIcon className={classes.icon} />
          </StyledIconButton>
        </SocialIcons>
      </Inner>
    </Container>
  )
}

export default ResourceLeftCol

const Container = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    width: 100%;
    max-width: 264px;
    position: relative;
  }
`
const Inner = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  top: 100px;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

const Course = styled.h6`
  color: ${theme.text.second};
  padding-bottom: 15px;
  margin-top: 18px;
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 280px;
  margin-bottom: 18px;
`

const Arrow = styled.img`
  height: 40px;
  transition: all 0.5s ease;
  position: relative;
  left: -10px;
  animation: arrowCourseTemplate ease 0.5s forwards 0.5s;
  ${BackLink}:hover & {
    transform: translateX(-20px);
  }
`

const Line = styled.div`
  display: inline;
  position: relative;
  width: 0%;
  height: 1px;
  background: ${theme.brand.blue};
  transition: all 0.5s ease;
  animation: growLineCourseTemplate ease 0.5s forwards 0.5s;
  ${BackLink}:hover & {
    transform: translateX(-40px);
  }
`

const Description = styled.p`
  color: ${theme.text.second};
  font-weight: 300;
`
const SocialIcons = styled.div`
  flex-direction: row;
`

const StyledIconButton = styled(IconButton)`
  background-color: ${theme.brand.blue} !important;
  color: white;
  margin: 4px;

  .root {
    background-color: ${theme.brand.blue} !important;
  }
`
